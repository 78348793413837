/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Lato_100Thin = require('./Lato_100Thin.ttf');
export const Lato_100Thin_Italic = require('./Lato_100Thin_Italic.ttf');
export const Lato_300Light = require('./Lato_300Light.ttf');
export const Lato_300Light_Italic = require('./Lato_300Light_Italic.ttf');
export const Lato_400Regular = require('./Lato_400Regular.ttf');
export const Lato_400Regular_Italic = require('./Lato_400Regular_Italic.ttf');
export const Lato_700Bold = require('./Lato_700Bold.ttf');
export const Lato_700Bold_Italic = require('./Lato_700Bold_Italic.ttf');
export const Lato_900Black = require('./Lato_900Black.ttf');
export const Lato_900Black_Italic = require('./Lato_900Black_Italic.ttf');
